import { createRouter, createWebHistory } from 'vue-router';

// Use lazy loading for other components
const TermsAndConditions = () => import('@/views/TermsAndConditions.vue');
const HomePage = () => import('@/views/HomePage.vue');
const PrivacyPolicyView = () => import('@/views/PrivacyPolicyView.vue');

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicyView
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/NotFound.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
