<template>
    <div class="bg-blue-600 text-white py-6 border-t border-blue-500">
        <!-- Container -->
        <div class="container mx-auto px-4 text-center">
            <p class="text-lg font-medium">
                &copy; 2023 Fixed Rate Finder. All rights reserved.
            </p>
            <!-- Address Line -->
            <p class="mt-2">
                123 Harbour View<br>
                Sydney, NSW 2000
            </p>
            <!-- Made-up Phone Number -->
            <p class="mt-2">
                +61 2 9123 4567
            </p>
            <!-- Links to Terms and Conditions and Privacy Policy -->
            <div class="mt-2 space-x-4">
                <router-link to="/terms-and-conditions" class="text-white hover:underline">Terms and
                    Conditions</router-link>
                <span>|</span>
                <router-link to="/privacy-policy" class="text-white hover:underline">Privacy Policy</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterSection'
}
</script>

<style scoped>
/* Additional styles if needed, but mostly it will be handled by Tailwind classes */
</style>
