import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import VueGtag from 'vue-gtag';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import './assets/styles.css';
import { db } from './firebase';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCoffee, faUser, faEye, faBalanceScale, faLightbulb } from '@fortawesome/free-solid-svg-icons';

library.add(faCoffee, faUser, faEye, faBalanceScale, faLightbulb);

const appInstance = createApp(App);

appInstance.component('font-awesome-icon', FontAwesomeIcon);
appInstance.use(VueTelInput);
appInstance.provide('db', db);
appInstance.use(router);

// Retrieve the Google Tag Manager ID from the environment variable
const GTM_ID = process.env.VUE_APP_GTM_ID || 'GTM-5RR8ZK5C';
appInstance.use(VueGtag, { config: { id: GTM_ID } });

appInstance.mount('#app');
